<template>
  <div>
    <member-breadcrumb :title="title"/>
    <project-form url="/project/create.do" @success="success"/>
  </div>
</template>

<script>
  import MemberBreadcrumb from '../common/MemberBreadcrumb'
  import ProjectForm from './ProjectForm'

  export default {
    name: 'ProjectCreate',
    components: {ProjectForm, MemberBreadcrumb},
    data () {
      return {
        title: '提交项目需求'
      }
    },
    methods: {
      success () {
        this.$message.success('项目需求提交成功，我们评估后会电脑联系您。')
        this.$router.push({name: 'projectList'})
      }
    }
  }
</script>

<style scoped>

</style>